<template>
  <el-row class="row-center">
    <el-col class="col">
      <Title2
        :title="'ALTA DE PACIENTE'"
        :label="'Por favor ingrese los datos para agendar una cita nueva'"
        :btnText="'DAR DE ALTA'"
        v-on:before-submit="beforeSubmit"
      />
      <div class="profile row">
        <div class="left">
          <el-container >
            <el-main>
              <p
                style="
                  color: #006EFF;
                  font-weight: bold;
                  font-size: 15px !important;
                  margin:0px;
                "
              >
                DATOS GENERALES
              </p>
              <el-form
                :model="form"
                ref="refId"
                :rules="rules"
                @keyup.enter.native="beforeSubmit()"
                @submit.prevent
              >
                <div class="row">
                  <el-form-item prop="firstName" label="Nombres">
                    <el-input
                      v-model="form.firstName"
                      @input="(val) => form.firstName = $capitalize(val)"
                      maxlength="60"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="lastName"
                    label="Apellidos"
                    style="padding-left:5%;padding-right:0%!important;"
                  >
                    <el-input
                      v-model="form.lastName"
                      @input="(val) => form.lastName = $capitalize(val)"
                      maxlength="60"
                    />
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item prop="birthdate" label="Fecha de nacimiento (aaaa/mm/dd)">
                    <el-date-picker
                      v-model="form.birthdate"
                      type="date"
                      value-format = "yyyy-MM-dd"
                      :picker-options="{
                        disabledDate(time) {
                          return time.getTime() > Date.now();
                        },
                      }"
                      placeholder="Seleccionar fecha"
                    />
                  </el-form-item>
                  <div style="padding-left: 5%; padding-right: 0% !important;">
                    <el-form-item
                      prop="sexo"
                      label="Sexo"
                      style="width:max-content;"

                    >
                      <el-select
                        v-model="form.sex"
                        placeholder="Seleccionar"
                        >
                          <el-option
                            v-for="item in sexo"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <el-form-item
                    prop="curp"
                    style="padding-right:0%!important;"
                    label="CURP"
                  >
                    <el-input
                      v-model="form.curp"
                      @input="upper($event)"
                      maxlength="18"
                    />
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item prop="phone" label="Teléfono Fijo">
                    <el-input
                      v-model="form.phone"
                      v-mask="'(999) 999-9999'"
                      placeholder="(___) ___-____"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="cellPhone"
                    label="Teléfono Particular"
                    style="padding-left:5%;padding-right:0%!important;"
                  >
                    <el-input
                      v-model="form.cellPhone"
                      v-mask="'(999) 999-9999'"
                      placeholder="(___) ___-____"
                    />
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item prop="extraPhone" label="Teléfono Extra">
                    <el-input
                      v-model="form.extraPhone"
                      v-mask="'(999) 999-9999'"
                      placeholder="(___) ___-____"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="cp" label="Código postal"
                    style="padding-left:5%;padding-right:0%!important;"
                  >
                    <el-input
                      v-model="form.cp"
                    />
                  </el-form-item>
                </div>
                <!-- <div class="row">
                  <el-form-item
                    prop="userId"
                    label="Seleccione especialista"
                    style="padding-right:0%!important;"
                  >
                    <el-select v-model="form.userId">
                      <el-option
                        v-for="user in users"
                        :key="user.id"
                        :label="`${user.firstName} ${user.lastName}`"
                        :value="user.id"
                      />
                    </el-select>
                  </el-form-item>
                </div> -->
                <!-- <el-form-item
                  prop="schedule"
                  label="¿Desea agendar cita con Trabajo Social?"
                  style="width: 50%!important;"
                >
                  <el-select v-model="socialSchedule">
                    <el-option
                      v-for="item in schedule"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item> -->
                <div>
                  <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="120px"
                    class="subsecuente"
                    label-position="top"
                  >
                  <el-form-item prop="region">
                    <p style="margin: 0;">Tipo De Cita</p>
                    <el-select
                      v-model="cita"
                      placeholder="Select"
                      >
                        <el-option
                          v-for="item in citaTipo"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item v-if="cita === 'Consulta General'">
                      <p style="margin: 0;">Médico</p>
                      <el-select v-model="medico" placeholder="Select">
                        <el-option
                          v-for="item in medicos"
                          :key="item.id"
                          :label="item.firstName + ' ' + item.lastName"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item> -->
                    <el-form-item
                      v-if="cita === 'Especialidad'"
                    >
                      <p style="margin: 0;">Especialidad</p>
                      <el-select
                        v-model="valueEspecialidad"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in specialty"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      prop="region"
                      v-if="cita === 'Consulta General'">
                      <p style="margin: 0;">Procedimientos de Consulta General</p>
                      <el-select
                        style="width: 60%;"
                        v-model="procedimientoConsultaGeneral"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in options.general"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <!-- <div
                      prop="region"
                      v-if="cita === 'Gabinete'"
                    >
                      <div style="margin: 25px 0 25px 0;width: 40%!important;">
                        <p
                          style="color:#006EFF;
                          font-weight:bold;font-size:15px!important;margin:0px;"
                        >
                          DATOS DE CITA
                        </p>
                        <div style="margin-left:10px; width:max-content;">
                          <h4 style="color:#49749F; font-size: 12px">Estudios Gabinete</h4>
                          <el-button
                              style="
                                background: #49749F;
                                color: #fff;
                                margin-bottom: 15px;
                              "
                              icon="el-icon-plus"
                              size="medium"
                              circle
                              @click="AgregarStudio()"
                            />
                          <div
                            class="estudio-costo"
                            v-for="(item, idx) in nuevosEstudios"
                            :key="item.id"
                          >
                            <div>
                            <strong>Estudio Seleccionado</strong>
                              <el-form-item
                                prop="estudiosValue"
                                style="
                                margin-top: 10px;
                                width: 80%;
                                "
                                size="mini"
                              >
                                <el-select
                                  v-model="nuevosEstudios[idx].gabineteId"
                                  filterable
                                >
                                  <el-option
                                    v-for="item in estudios"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                  />
                                </el-select>
                              </el-form-item>
                            </div>
                            <div style="margin:0 18px 0 10px;">
                              <strong>Fecha Seleccionada</strong>
                              <p>{{nuevosEstudios[idx].date}}</p>
                            </div>
                            <h4>{{nuevosEstudios[idx].id}}</h4>
                            <div style="margin:0 18px 0 10px;">
                              <strong>Hora Seleccionada</strong>
                              <p>{{nuevosEstudios[idx].time}}</p>
                            </div>
                            <div>
                              <el-button
                                type="danger"
                                style="
                                  color: #fff;
                                  font-size: 8px;
                                "
                                icon="el-icon-minus"
                                size="mini"
                                circle
                                @click="EliminarStudio(idx)"
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <el-form-item
                      prop="region"
                      v-if="valueEspecialidad"
                    >
                      <p style="margin: 0;">Procedimiento de {{ nameEspecialidad }}</p>
                      <el-select
                        style="width: 60%;"
                        v-model="procedimiento"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in options[nameEspecialidad]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
                <el-form-item
                  label="Comentarios adicionales del paciente"
                  prop="comments"
                  style="width:100%;"
                >
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    v-model="form.comments"
                    style="width: 100%!important;"
                    maxlength="255"
                  />
                </el-form-item>
                <div class="row">
                  <el-form-item
                    style="width: 100%!important;margin-bottom: 0px!important;margin-right:10px"
                  >
                    <el-button
                      type="primary"
                      @click="beforeSubmit(false)"
                      style="background-color: #FFFFFF;color:#2159FF;float:right;"
                    >DAR DE ALTA</el-button>
                  </el-form-item>
                  <el-form-item style="margin-bottom: 0px!important">
                    <el-button
                      type="primary"
                      @click="beforeSubmit(true)"
                      style="background-color: #6993FF;color:#fff;float:right;"
                    >DAR DE ALTA Y REGISTRAR CITA</el-button>
                  </el-form-item>
                </div>
              </el-form>
            </el-main>
          </el-container>
        </div>
        <div :class="right">
          <div class="calendario" v-if="cita === 'Especialidad'">
            <h4> Especialidad </h4>
            <Calendar
              v-on:selected-hour="selectedHour"
              v-on:selected-date="selectedDate"
              :disabledsEspeciality="reservedHoursProcedure"
            />
          </div>
          <div class="calendario" v-if="cita === 'Consulta General'">
            <h4> Consulta General </h4>
            <Calendar
              v-on:selected-hour="selectedHour"
              v-on:selected-date="selectedDate"
              :disableds="reservedHours"
            />
          </div>
          <div class="calendario" v-if="cita === 'Gabinete'">
            <h4> Gabinete </h4>
            <Calendar
              v-on:selected-hour="selectedHourGabinete"
              v-on:selected-date="selectedDateGabinete"
              :disabledsEspeciality="reservedHoursProcedure"
            />
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import Title2 from '../../components/Title2';
import Calendar from '../../components/Calendar';
import optionsMixin from '../appointments/mixins/options';

// eslint-disable-next-line
const moment = require('moment');
export default {
  data() {
    const isValidPhone = (rule, value, cb) => {
      const regexp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      if (value.trim() && !regexp.test(value)) {
        return cb(new Error('El campo debe ser un número teléfonico válido.'));
      }
      return cb();
    };
    return {
      nuevosEstudios: [],
      nuevosEstudiosCC: [],
      estudios: [
        {
          id: 1,
          name: 'CV',
          value: 'CV',
        },
        {
          id: 2,
          name: 'OCT',
          value: 'OCT',
        },
        {
          id: 3,
          name: 'FAG',
          value: 'FAG',
        },
        {
          id: 4,
          name: 'FOTOS',
          value: 'FOTOS',
        },
        {
          id: 5,
          name: 'PAQUIS',
          value: 'PAQUIS',
        },
        {
          id: 6,
          name: 'IOL',
          value: 'IOL',
        },
        {
          id: 7,
          name: 'VISITANTE',
          value: 'VISITANTE',
        },
        {
          id: 8,
          name: 'ORBSCAN',
          value: 'ORBSCAN',
        },
        {
          id: 9,
          name: 'OPD',
          value: 'OPD',
        },
        {
          id: 10,
          name: 'PENTACAM',
          value: 'PENTACAM',
        },
        {
          id: 11,
          name: 'ATLAS',
          value: 'ATLAS',
        },
        {
          id: 12,
          name: 'CONT/CELULAS',
          value: 'CONT/CELULAS',
        },
        {
          id: 13,
          name: 'INMERSION',
          value: 'INMERSION',
        },
        {
          id: 14,
          name: 'PAM',
          value: 'PAM',
        },
        {
          id: 15,
          name: 'NIDEK',
          value: 'NIDEK',
        },
      ],
      countCitas: [],
      dialogVisible: false,
      cita: null,
      citaTipo:
      [
        {
          value: 'Consulta General',
          label: 'Consulta General',
        },
        {
          value: 'Especialidad',
          label: 'Especialidad',
        },
      ],
      sexo: [
        {
          value: 'Femenino',
          label: 'Femenino',
        },
        {
          value: 'Masculino',
          label: 'Masculino',
        },
      ],
      medicos: [],
      medico: null,
      valueEspecialidad: null,
      nameEspecialidad: null,
      procedimientoConsultaGeneral: null,
      procedimiento: null,
      especialidad: [],
      cantidadCitas: [],
      value: '',
      right: 'right',
      specialty: [],
      selectedRole: 'MD',
      roles: [{ id: 'MD', name: 'MÉDICO' }],
      reservedHoursProcedure: [],
      citaHora: [],
      phoneDisabled: true,
      celDisabled: true,
      emailDisabled: true,
      disabled: true,
      // languages: lang,
      language: 'es',
      date: null,
      hour: null,
      procedure: '',
      form: {
        firstName: '',
        lastName: '',
        birthdate: '',
        sex: '',
        curp: '',
        phone: '',
        extraPhone: '',
        cellPhone: '',
        comments: '',
        userId: null,
        cp: '',
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        cpu: '',
      },
      loading: false,
      loadingService: null,
      socialSchedule: false,
      schedule: [
        {
          value: true,
          label: 'SI',
        },
        {
          value: false,
          label: 'NO',
        },
      ],
      rules: {
        firstName: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        phone: [
          {
            validator: isValidPhone,
            trigger: 'blur',
          },
        ],
        birthdate: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        sex: [
          {
            required: true,
            message: 'El campo es requerido',
          },
        ],
        extraPhone: [
          {
            validator: isValidPhone,
            trigger: 'blur',
          },
        ],
        cellPhone: [
          {
            validator: isValidPhone,
            trigger: 'blur',
          },
        ],
        comments: [
          {
            required: false,
            message: 'El campo no puede estar vacío.',
          },
        ],
        userId: [
          {
            required: true,
            message: 'El campo no puede estar vacío.',
            trigger: 'blur',
          },
          {
            type: 'number',
            message: 'Formato no válido',
            trigger: 'onchange',
          },
        ],
        curp: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        cp: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
      },
      departments: [],
      users: [],
      reservedHours: [],
    };
  },
  mixins: [optionsMixin],
  components: {
    Title2,
    Calendar,
  },
  computed: {
    userId() {
      return this.form.userId;
    },
    especialistaId() {
      return this.valueEspecialidad;
    },
  },
  methods: {
    AgregarStudio() {
      this.procedure = null;
      this.hour = null;
      if (this.procedure === '' && this.nuevosEstudios.length >= 1) {
        const n = this.nuevosEstudios.length - 1;
        if (this.nuevosEstudios[n].procedure === '') {
          window.alert('Elija Un Estudio');
          return;
        }
      }
      // Get form vars
      const {
        comments,
        userId,
      } = this.form;
      const type = 'CITA GABINETE';
      const info = {
        procedure: null,
        time: this.hour,
        date: this.date,
        type,
        location: null,
        specialityId: null,
        subject: 'Gabinete',
        userId,
        comments,
        gabineteId: this.procedure,
        firstAppointment: true,
      };
      this.nuevosEstudiosCC.push(info);
      this.nuevosEstudios = this.nuevosEstudiosCC;
      this.procedure = null;
      this.hour = null;
    },
    EliminarStudio(index) {
      this.nuevosEstudios.splice(index, 1);
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm('Do you want to submit?')
        .then(() => {
          this.loading = true;
          this.timer = setTimeout(() => {
            done();
            // animation takes time
            setTimeout(() => {
              this.loading = false;
            }, 400);
          }, 700);
        })
        .catch(() => {});
    },
    async selectedHourGabinete(value) {
      this.time = value;
      this.hour = value;
      this.ultimo = this.nuevosEstudios.length - 1;
      this.nuevosEstudios[this.ultimo].time = value;
    },
    selectedDateGabinete(value) {
      this.date = value;
      this.ultimo = this.nuevosEstudios.length - 1;
      this.nuevosEstudios[this.ultimo].date = value;
    },
    upper(e) {
      this.form.curp = e.toUpperCase();
    },
    dateFormat(e) {
      if (this.form.birthdate.length === 4 || this.form.birthdate.length === 7) {
        this.form.birthdate = `${e.toUpperCase()}/`;
      }
    },
    selectedHour(value) {
      this.hour = value;
    },
    contadorOcurrenciasHoras() {
      this.dialogVisible = true;
      const contador = {};
      for (let i = 0; i < this.cantidadCitas.length; i++) {
        const element = this.cantidadCitas[i].time;
        contador[element] = contador[element] ? contador[element] + 1 : 1;
      }
      this.countCitas = contador;
    },
    selectedDate(value) {
      this.date = value;
    },
    beforeSubmit(shouldCreateAppointment) {
      this.rules.userId[0].required = !!shouldCreateAppointment;
      this.$refs.refId.validate((isValid) => {
        if ((isValid && this.hour && this.date) || (isValid && !shouldCreateAppointment)) {
          if (this.form.sex && this.form.birthdate) {
            this.submitPatient(shouldCreateAppointment);
          } else if (!this.form.sex) {
            this.$message({
              type: 'error',
              message: 'Seleccione el sexo del paciente',
            });
          } else if (!this.form.birthdate) {
            this.$message({
              type: 'error',
              message: 'Seleccione la Fecha de nacimiento del paciente',
            });
          }
        } else if (shouldCreateAppointment) {
          this.$message({
            type: 'error',
            message: 'Seleccione la fecha y hora correcta',
          });
        }
      });
    },
    submitWithDate() {
      const model = this.form;
      model.appointment = {
        date: this.date,
        hour: this.hour,
      };
      if (this.form.socialSchedule) {
        const id = this.form.curp;
        this.$router.push({ path: `admin/appointments/social/${id}` });
      }
    },
    async getReservedHours() {
      try {
        this.loading = true;
        if (this.date) {
          const response = await this.axios(`patient/subsecuente/md/reserved-schedules?date=${this.date}`);
          const { data } = response;
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHours = data;
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getReservedHoursProcedure() {
      try {
        this.loading = true;
        if (this.date) {
          const response = await this.axios(`patient/subsecuente/esp/reserved-schedules/${this.valueEspecialidad}?date=${this.date}`);
          const { data } = response;
          this.cantidadCitas = data;
          for (let index = 0; index < data.length; index++) {
            this.citaHora[index] = data[index].time;
          }
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHoursProcedure = data;
            const prereserved = [];
            for (let index = 0; index < data.length; index++) {
              // this.reservedHoursProcedure[index] = data[index].time;
              const elemento = data[index].time;
              prereserved[elemento] = prereserved[elemento] ? prereserved[elemento] + 1 : 1;
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getReservedHoursProcedureGabinete() {
      try {
        this.loading = true;
        if (this.date) {
          const response = await this.axios.get(`patient/subsecuente/esp/reserved-schedules-gabinete/${this.procedimiento}?date=${this.date}`);
          const { data } = response;
          this.cantidadCitas = data;
          for (let index = 0; index < data.length; index++) {
            this.citaHora[index] = data[index].time;
          }
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHoursProcedure = data;
            const prereserved = [];
            for (let index = 0; index < data.length; index++) {
              // this.reservedHoursProcedure[index] = data[index].time;
              const elemento = data[index].time;
              prereserved[elemento] = prereserved[elemento] ? prereserved[elemento] + 1 : 1;
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getUsers() {
      try {
        this.loading = true;
        const response = await this.axios.get('patient/appointment/users/MD');
        const { data } = response;
        if (Array.isArray(data)) {
          this.users = data;
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getMedicos() {
      try {
        this.loading = true;
        const response = await this.axios.get('patient/appointment/users/MD');
        const { data } = response;
        if (Array.isArray(data)) {
          this.medicos = data;
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getSpeciality() {
      try {
        this.loading = true;
        const request = await this.axios.get('/patient/specialty/all/v2');
        const { data } = request;
        if (request) {
          data.forEach((element) => {
            this.specialty.push({ id: element.id, name: element.name });
          });
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getSpecialist() {
      try {
        this.loading = true;
        const res = await this.axios.get('patient/specialist/all');
        if (res) {
          const medicos = res.data;
          this.especialidad = medicos;
        }
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async submitAppointment(patientId) {
      try {
        this.loading = true;
        // Get form vars
        const {
          comments,
        } = this.form;
        const type = 'PRIMERA CITA';
        if (this.cita === 'Especialidad') {
          const response = await this.axios.post(`patient/subsecuente/especialidad/${patientId}`, {
            type,
            location: null,
            date: this.date,
            time: this.hour,
            specialityId: this.valueEspecialidad,
            procedure: this.procedimiento,
            subject: 'Especialidad',
            userId: this.userId,
            comments,
            firstAppointment: true,
          });
          if (response) {
            const message = 'Se agendó cita cita de primera vez';
            await this.axios.post(`patient/changelog/${patientId}`, {
              title: 'Agenda',
              message,
            });
            this.$notify({
              type: 'success',
              message,
            });
            this.loading = false;
          }
        }
        if (this.cita === 'Consulta General') {
          const response = await this.axios.post(`patient/subsecuente/medico/${patientId}`, {
            type,
            location: null,
            date: this.date,
            time: this.hour,
            specialityId: null,
            procedure: this.procedimientoConsultaGeneral,
            subject: 'Médico',
            userId: this.medico,
            comments,
            firstAppointment: true,
          });
          if (response) {
            const message = 'Se agendó cita de primera vez';
            await this.axios.post(`patient/changelog/${patientId}`, {
              title: 'Agenda',
              message,
            });
            this.$notify({
              type: 'success',
              message,
            });
            this.loading = false;
          }
        }
        if (this.cita === 'Gabinete') {
          try {
            const data = this.nuevosEstudios;
            const requests = [];
            requests.concat(data.map((item) => this.axios.post(`patient/subsecuente/gabinete/${patientId}`, {
              ...item,
            })));
            if (requests) {
              const message = 'Se agendó cita subsecuente';
              await this.axios.post(`patient/changelog/${patientId}`, {
                title: 'Agenda',
                message,
              });
              this.$notify({
                type: 'success',
                message,
              });
              this.loading = false;
              let path = '/';
              if (this.socialSchedule && patientId) {
                path = `/admin/appointments/social/${patientId}`;
              }
              setTimeout(() => {
                this.$router.replace({ path });
              });
            }
            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.$errorNotify(error);
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$errorNotify(error);
      }
    },
    async submitPatient(shouldCreateAppointment) {
      try {
        // Initialize loading service
        this.loading = true;

        // Get form vars
        const {
          curp,
          firstName,
          lastName,
          birthdate,
          phone,
          extraPhone,
          cellPhone,
          sex,
          cp,
        } = this.form;

        const fechaNacimient = birthdate.toString();
        const exists = await this.axios.get(`patient/specialty/${firstName}/${lastName}/${fechaNacimient}`);
        if (exists.data.length > 0) {
          this.$message({
            message: 'El Paciente Ya Se Encuentra Registrado',
            type: 'warning',
          });
        } else {
        // Register a new patient
          const response = await this.axios.post('patient', {
            curp,
            firstName,
            lastName,
            phone,
            extraPhone,
            cellPhone,
            birthdate,
            sex,
            cp,
          });
          const { data } = response;
          if (data) {
            const { patient: patientId } = data;
            let message = 'El paciente ha sido registrado';
            await this.axios.post(`patient/changelog/${patientId}`, {
              title: 'Agenda',
              message,
            });
            if (shouldCreateAppointment) {
              message = 'Se agendó la primera cita del paciente';
              this.submitAppointment(patientId);
            }
            this.$notify({
              type: 'success',
              message,
            });
            let path = '/';
            if (this.socialSchedule && data) {
              path = `/admin/appointments/social/${data.patient}`;
            }
            setTimeout(() => {
              this.$router.replace({ path });
            });
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getMedicos();
    // await this.getSpecialist();
    await this.getSpeciality();
  },
  watch: {
    loading(val) {
      if (val && !this.loadingService) {
        this.loadingService = this.$loading({
          fullscreen: true,
          lock: true,
          text: 'Cargando',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      } else if (this.loadingService) {
        this.loadingService.close();
        this.loadingService = null;
      }
    },
    selectedRole(val) {
      if (val) {
        this.form.userId = null;
        if (this.cita === 'Especialidad') {
          this.reservedHoursProcedure = [];
          this.getReservedHoursProcedure();
        }
        // if (this.cita === 'Gabinete') {
        //   this.reservedHoursProcedure = [];
        //   this.getReservedHoursProcedureGabinete();
        // }
        if (this.cita === 'Consulta General') {
          this.reservedHours = [];
          this.getReservedHours();
        }
        this.getUsers();
      }
    },
    cita(val) {
      if (val) {
        if (this.cita === 'Especialidad') {
          this.reservedHoursProcedure = [];
          this.getReservedHoursProcedure();
        }
        if (this.cita === 'Consulta General') {
          this.reservedHours = [];
          this.getReservedHours();
        }
        // if (this.cita === 'Gabinete') {
        //   this.reservedHours = [];
        //   this.getReservedHoursProcedureGabinete();
        // }
      }
    },
    date(val) {
      if (val) {
        if (this.cita === 'Especialidad') {
          this.reservedHoursProcedure = [];
          this.getReservedHoursProcedure();
        }
        if (this.cita === 'Consulta General') {
          this.reservedHours = [];
          this.getReservedHours();
        }
        if (this.cita === 'Gabinete') {
          this.reservedHours = [];
          this.getReservedHoursProcedureGabinete();
        }
      }
    },
    async valueEspecialidad() {
      const name = this.specialty.find((element) => element.id === this.valueEspecialidad);
      this.nameEspecialidad = name.name;
      this.reservedHoursProcedure = [];
      await this.getReservedHoursProcedure();
    },
  },
};
</script>

<style lang="scss">
.el-form-item__label {
  font-weight: bold;
  color: #000;
  float: left;
}
</style>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
}
.middle {
  height: 60%;
  width: 2px;
  color: #2242cb;
}
.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.el-row {
  height: 100%;
  .el-col {
    width: 95%;
    height: 98%;
    .profile {
      padding-top: 10px;
      height: 90%;
      width: 100%;
      .left {
        width: 70%;
        .el-container {
          background-color: #fff !important;
          height: 100% !important;
          min-height: 0%;
          .el-main {
            padding: 10px 25px;
            margin: 0px;
            .el-form {
              .el-form-item {
                width: 50%;
                margin-bottom: 18px !important;
                .el-form-item__content {
                  width: 90% !important;
                  .el-input {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-track {
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background: #c2bebf;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #b2aeaa;
        }
      }
      .right {
        height: 100%;
        width: 30%;
        background-color: #fff;
        overflow-y: auto;
      }
      .card-subsecuente {
        width: 72%;
        color:#6a41bdb6;;
        padding: 15px;
        background-color: #fff;
        text-align: center;
        .el-button {
          color: #fff;
          background-color: #6a41bdb6;
        }
      }
    }
  }
}
</style>
